import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { mapAdditionalContentTypeUrl } from "src/app/helpers/common";
import { User } from "src/app/models/user.model";
import { RequestStatus } from "src/app/services/bpp.webapi/client";
import { AppState } from "src/app/store/app.state";
import { ConfigService } from "../../../../config/config.service";
import * as playerHelper from "../../../../helpers/player-helper";
import { Roles } from "../../../../models/enums";
import {
  AttendeeDto,
  DashboardItemType,
} from "../../../../services/bpp.webapi/dashboard.client";
import { getStepsStatus } from "../../dashboard.helper";
import { IDashboardMessage } from "../../models/dashboard-message.interface";
import { ISessionData } from "../../models/session-data.interface";

enum ItemsStatuses {
  draft = "Draft",
  rejected = "Rejected",
  approved = "Approved",
  submitted = "Submitted",
  requests = "Requests",
}

@Component({
  selector: "app-dashboard-lfp-Info",
  templateUrl: "./dashboard-lfp-info.component.html",
  styleUrls: ["./dashboard-lfp-info.component.scss"],
})
export class DashboardLfpInfoComponent implements OnInit {
  isLog = false;
  isAttendees = false;
  resources: {
    review: string;
    details: string;
  };
  @Input() data: IDashboardMessage;
  session: ISessionData;
  hasDataConfirmed: boolean;
  user: User;
  items: any[];
  private isSimplified: boolean;
  statusBtnLabel: string;
  requestsItem: any;
  showRequestsMenu = false;

  constructor(
    private router: Router,
    config: ConfigService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {
    this.isLog = config.isDebug();
    this.isSimplified = config.hasFeature("dashboard.simplified");
  }

  ngOnInit() {
    this.hasDataConfirmed = this.data.confirmed != "";
    this.session = this.data.data;
    this.store
      .select((state) => state.user.currentUser)
      .subscribe((c) => {
        this.user = c;
        this.items = getStepsStatus(this.session, this.user.companyId);
        this.statusBtnLabel = this.items.some(
          (item) => item.label === ItemsStatuses.requests
        )
          ? this.items[this.items.length - 2].label
          : this.items[this.items.length - 1].label;
        this.requestsItem = this.items.find(
          (item) => item.label === ItemsStatuses.requests
        );
      });

    this.isAttendees =
      this.session.attendees &&
      this.session.attendees.length > 0 &&
      this.session.attendees.filter((a) => a.fullName !== null).length > 0;
    this.resources = { review: "", details: "" };
    this.translate
      .get("ReviewBtn")
      .subscribe((value) => (this.resources.review = value));
    this.translate
      .get("DetailsBtn")
      .subscribe((value) => (this.resources.details = value));
  }

  goToDetail(data: ISessionData) {
    if (
      data.dashboardItemType === DashboardItemType.AdditionalContent ||
      data.dashboardItemType === DashboardItemType.AdditionalContentRequest
    ) {
      if (this.isSimplified) {
        this.router.navigate([
          `/additional-content/${mapAdditionalContentTypeUrl(
            data.additionalContentType,
            this.isSimplified
          )}/detail/${data.eventId}`,
        ]);
        return;
      }
    }

    if (data.match && data.match.matchId) {
      if (this.isSimplified) {
        this.router.navigate([`/club-interviews/detail/${data.match.matchId}`]);
      } else {
        this.router.navigate([
          `/fixtures/detail/${data.match.matchId}/nonLive/${data.isNonLiveFixtureEvent}`,
        ]);
      }
    } else if (data.interviewee && data.monthId) {
      this.router.navigate([`/interviews/detail/${data.monthId}`]);
    } else if (data.eventId) {
      this.router.navigate([`/trainings/detail/${data.eventId}`]);
    }
  }

  isLeagueInterview(session: ISessionData) {
    return session.dashboardItemType === 6 || session.dashboardItemType === 7;
  }

  onDetailButtonClick(id: string) {
    this.goToDetail(this.session);
  }

  isManager(player: AttendeeDto) {
    return playerHelper.isManagerForDashboard(player);
  }

  getReviewButtonText() {
    switch (this.session.userRole) {
      case Roles.Admin:
      case Roles.League:
        return this.resources.review;
      case Roles.Club:
      case Roles.Broadcaster:
      case Roles.PLPBroadcaster:
        return this.resources.details;
      default:
        return this.resources.details;
    }
  }

  isReviewBtnVisible() {
    if (
      (this.session.userRole === Roles.League ||
        this.session.userRole === Roles.Admin) &&
      this.session.isRejected === true
    ) {
      return false;
    }
    return true;
  }

  getPlayerRole(player: AttendeeDto) {
    return `(${player.kind})`;
  }

  getPlayers(session: ISessionData) {
    let players = "";
    if (session.attendees && session.attendees.length > 0) {
      session.attendees.forEach((element, index) => {
        players = players.concat(element.fullName);
        if (index != session.attendees.length - 1) {
          players = players.concat(", ");
        }
      });
    } else if (session.interviewee) {
      players = players.concat(session.interviewee);
    }

    return players;
  }

  getRequestStyleColor(status: number): string {
    if (status === RequestStatus.Reject || status === RequestStatus.Withdrawn) {
      return "request-rejected";
    } else if (
      status === RequestStatus.Submitted ||
      status === RequestStatus.Draft
    ) {
      return "request-submitted";
    } else if (status === RequestStatus.Approved) {
      return "request-approved";
    }
    return "";
  }

  mouseOver() {
    this.showRequestsMenu = true;
  }

  mouseOut() {
    this.showRequestsMenu = false;
  }
}
